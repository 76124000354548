import React from 'react';
import { Routes, Route } from 'react-router-dom'
import Layout from './features/Layout'
import Home from './features/Home/Home'
import CostHistory from './features/CostHistory/CostHistory';
import Test from './features/Test/Test';
import { db } from './db'

function App() {
  db.histories.toArray().then(res => {
    if (res.length === 0) {
      const fakeData = [
        {
          category: '食',
          title: '早午餐',
          price: 210,
          createdAt: new Date('2022/06/27 13:45:00')
        },
        {
          category: '行',
          title: '高鐵車票',
          price: 670,
          createdAt: new Date('2022/06/27 15:21:00')
        },
        {
          category: '食',
          title: '晚餐',
          price: 90,
          createdAt: new Date('2022/06/27 19:36:00')
        },
        {
          category: '育',
          title: '買書',
          price: 360,
          createdAt: new Date('2022/06/28 00:45:00')
        },
        {
          category: '樂',
          title: '看電影',
          price: 360,
          createdAt: new Date('2022/06/28 10:18:00')
        },
        {
          category: '食',
          title: '午餐',
          price: 110,
          createdAt: new Date('2022/06/28 13:52:00')
        }
      ]
      db.histories.bulkAdd(fakeData)
    }
  })

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="history" element={<CostHistory />} />
        <Route path="test" element={<Test />} />
      </Route>
    </Routes>  
  );
}

export default App;
