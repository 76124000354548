import React from 'react'
import { Card } from 'flowbite-react'
import { useNavigate } from "react-router-dom";
import { db } from '../../db'
import { useLiveQuery } from "dexie-react-hooks";
import dayjs from 'dayjs'

const TodayCard = () => {
  const navegation = useNavigate();

  // get data from indexedDB
  const todayCostQuery = useLiveQuery(async () => {
    const today = dayjs().format('YYYY/MM/DD')
    return await db.histories.where('createdAt').between(new Date(`${today} 00:00:00`), new Date(`${today} 23:59:59`)).toArray();
  })
  const totalPrice = todayCostQuery ? todayCostQuery.reduce((sum, val) => sum + val.price, 0) : 0
  const totalAmount = todayCostQuery ? todayCostQuery.length : 0
  //

  return (
    <Card onClick={() => navegation('/history')}>
      <p className="font-normal text-gray-700 dark:text-gray-400">
      { totalAmount ? `今日花費，共 ${totalAmount} 筆` : '今日還沒有記錄哦！'}
      </p>
      <h5 className="text-2xl font-bold tracking-tight text-gray-900 text-right dark:text-white">
        $ {totalPrice} 元
      </h5>
    </Card>
  )
}

export default TodayCard