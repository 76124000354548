import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useLiveQuery } from 'dexie-react-hooks'
import { db } from '../../db'
import { Card, Timeline, Breadcrumb, Badge } from 'flowbite-react'
import _ from 'lodash'
import dayjs from 'dayjs'

const CostHistory = () => {
  // 從 DB 同步資料
  const navegation = useNavigate()
  const historiesQuery = useLiveQuery(async () => {
    return await db.histories.toArray()
  })

  //
  let histories = []
  let historiesDiv = []
  //
  function badgeColor (val) {
    const options = ['食', '衣', '住', '行', '育', '樂', '其他']
    const colors = ['info', 'failure', 'pink', 'success', 'failure', 'purple', 'pink']
    return colors[options.findIndex(e => e === val)]
  }
  if (historiesQuery) {
    histories = _.groupBy(historiesQuery, (el) => {
      return dayjs(el.createdAt).format('YYYY/MM/DD')
    })
    // 照日期排序
    const sortHistories = Object.keys(histories).sort((a, b) =>  parseInt(dayjs(b).format('YYYYMMDD')) - parseInt(dayjs(a).format('YYYYMMDD')))
    sortHistories.forEach((date) => {
      // 照時間排序
      const sorted = histories[date].sort(
        (a, b) =>
          parseInt(dayjs(b.createdAt).format('HHmm')) -
          parseInt(dayjs(a.createdAt).format('HHmm'))
      )
      const timelineItem = sorted.map((item) => {
        return (
          <Timeline.Item key={`timeline_${date}_${item.id}`}>
            <Timeline.Point />
            <Timeline.Content>
              <Timeline.Time>
                {dayjs(item.createdAt).format('HH:mm')}
              </Timeline.Time>
              <Timeline.Title>
                <div className="flex flex-wrap gap-2 items-center">
                  <Badge color={badgeColor(item.category)} size="md">
                    {item.category}
                  </Badge>
                  <span className="text-md">{item.title}</span>
                  <span className="font-normal text-sm">${item.price}</span>
                </div>
              </Timeline.Title>
            </Timeline.Content>
          </Timeline.Item>
        )
      })

      // 組合每天的總計
      const timelineSum = (
        <Timeline.Item>
          <Timeline.Point />
          <Timeline.Content>
            <Timeline.Time>
              <span className="font-bold text-md text-black">總計 ${ histories[date].reduce((sum, val) => sum + val.price, 0) }</span>
            </Timeline.Time>
          </Timeline.Content>
        </Timeline.Item>
      )

      historiesDiv.push(
        <Card key={`date_${date}`}>
          <p className="font-bold text-lg">{date}</p>
          <Timeline>
            {timelineSum}
            {timelineItem}
          </Timeline>
        </Card>
      )
    })
  }

  return (
    <div>
      <Breadcrumb className="mb-5">
        <Breadcrumb.Item onClick={() => navegation('/')}>首頁</Breadcrumb.Item>
        <Breadcrumb.Item>我的紀錄</Breadcrumb.Item>
      </Breadcrumb>
      <div className="grid grid-flow-row gap-2">
        {historiesDiv}
      </div>
      
    </div>
  )
}

export default CostHistory
