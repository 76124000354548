import React, { useCallback, useEffect, useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
//
import { Navbar, Button } from 'flowbite-react'
import OfflineMessage from '../components/OfflineMessage'

const Layout = () => {
  const navigator = useNavigate()
  const [mainClass, setMainClass] = useState('py-8')
  const [titleBar, setTitleBar] = useState()
  //
  //
  const setWindowControlsOverlay = useCallback(() => {
    function setWindowControlsOverlayFunc() {
      setMainClass('py-16')
      setTitleBar(
        <div className="titleBar flex justify-between mb-8">
          <span className="text-sm">我的記帳小幫手Demo</span>
          <Button gradientDuoTone="cyanToBlue" size="xs" onClick={() => navigator('/test')}>
            訂閱$99/月
          </Button>
        </div>
      )
    }
    setWindowControlsOverlayFunc()
  }, [navigator])
  //
  const cancelWindowControlsOverlay = useCallback(() => {
    function cancelWindowControlsOverlayFunc() {
      setMainClass('py-8')
      setTitleBar(
        <Navbar fluid={true} rounded={true}>
          <Navbar.Brand>
            <span className="self-center whitespace-nowrap text-xl font-semibold dark:text-white">
              我的記帳小幫手Demo
            </span>
          </Navbar.Brand>
        </Navbar>
      )
    }
    cancelWindowControlsOverlayFunc()
  },[])
  //
  //
  useEffect(() => {
    // 確認有無啟用 windowControlsOverlay
    if ('windowControlsOverlay' in window.navigator) {
      if (window.navigator.windowControlsOverlay.visible) {
        setWindowControlsOverlay()
      } else {
        cancelWindowControlsOverlay()
      }
      window.navigator.windowControlsOverlay.addEventListener(
        'geometrychange',
        (e) => {
          if (e.visible) {
            setWindowControlsOverlay()
          } else {
            cancelWindowControlsOverlay()
          }
        }
      )
    } else {
      cancelWindowControlsOverlay()
    }
  }, [setWindowControlsOverlay, cancelWindowControlsOverlay])

  return (
    <div className="min-h-screen main-bg">
      <OfflineMessage />
      {titleBar}
      <div className={`overflow-hidden ${mainClass}`}>
        <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
          <Outlet />
        </div>
      </div>
    </div>
  )
}

export default Layout
