import React, { useState } from 'react'
import { Label, TextInput, Button, Select, Card } from 'flowbite-react'
import { db } from '../../db'
//
import TodayCard from './TodayCard'

const Home = () => {

  const itemInit = { category: '食', title: '', price: '' }
  const [item, setItem] = useState(itemInit)

  const category = ['食', '衣', '住', '行', '育', '樂', '其他']
  const categoryOption = category.map(el => <option key={`option_${el}`}>{el}</option>)

  function handleSubmit (event) {
    event.preventDefault();
    const newItem = {...item, createdAt: new Date() }
    db.histories.add(newItem).then(e => {
      console.log('item added index =>', e, newItem)
      setItem(itemInit)
    })
  }

  return (
    <div>
      <TodayCard />
      <div className="py-2"></div>
      <Card>
        <form className="flex flex-col gap-4" onSubmit={handleSubmit}>
          <div id="select">
            <div className="mb-2 block">
              <Label htmlFor="countries" value="類別" />
            </div>
            <Select id="countries" required={true} onChange={event => setItem({ ...item, category: event.target.value })}>
              {categoryOption}
            </Select>
          </div>
          <div>
            <div className="mb-2 block">
              <Label htmlFor="title" value="名稱" />
            </div>
            <TextInput
              id="title"
              type="text"
              placeholder="買菜、吃飯、看電影"
              required={true}
              onChange={event => setItem({ ...item, title: event.target.value })}
              value={item.title}
            />
          </div>
          <div>
            <div className="mb-2 block">
              <Label htmlFor="amount" value="金額" />
            </div>
            <TextInput id="amount" type="number" required={true} value={item.price} onChange={event => setItem({ ...item, price: parseInt(event.target.value) })} />
          </div>
          <div className="place-self-center">
            <Button gradientDuoTone="pinkToOrange" type="submit">
              <div className="w-24">記帳GO!</div>
            </Button>
          </div>
        </form>
      </Card>
    </div>
  )
}

export default Home
