import React from 'react'
import { Toast } from 'flowbite-react'
import { Offline, } from "react-detect-offline";
import DynamicHeroIcon from './DynamicHeroIcon'
const OfflineMessage = () => {
  return (
    <Offline>
      <div className="fixed bottom-0 right-0 mx-2 mb-6 z-50">
        <Toast>
          <div className="inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg bg-red-100 text-red-500 dark:bg-red-800 dark:text-red-200">
            {/* <HiX className="h-5 w-5" /> */}
            <DynamicHeroIcon icon="StatusOfflineIcon" className="h-5 w-5"></DynamicHeroIcon>
          </div>
          <div className="ml-3 text-sm font-normal">無網路連線，但資料還是會保存.</div>
        </Toast>
      </div>
    </Offline>
  )
}

export default OfflineMessage
