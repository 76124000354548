import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Breadcrumb, Card, Button } from 'flowbite-react'
const Test = () => {
  const navegator = useNavigate()

  function setAppBadge() {
    const unreadCount = 24
    navigator.setAppBadge(unreadCount).catch((error) => {
      //Do something with the error.
      console.warn(error)
    })
  }
  function clearAppBadge() {
    navigator.clearAppBadge().catch((error) => {
      // Do something with the error.
      console.warn(error)
    })
  }

  function shareApp() {
    const options = {
      title: '我的記帳小幫手Demo',
      text: '我這裡有一批好棒的網頁要跟你推薦！😊😊',
      url: 'https://pwa-demo.renzhou.dev/',
    }
    navigator.share(options)
  }

  function requestNotification() {
    Notification.requestPermission().then(function (result) {
      console.log(result)
      var n = new Notification('Hello 今天記帳了嗎 😉 !?')
      n.onshow = function () {
        setTimeout(n.close, 5000)
      }
    })
  }

  return (
    <div>
      <Breadcrumb className="mb-5">
        <Breadcrumb.Item onClick={() => navegator('/')}>首頁</Breadcrumb.Item>
        <Breadcrumb.Item>訂閱？</Breadcrumb.Item>
      </Breadcrumb>
      <div className="grid grid-flow-row gap-2">
        <Card>
          <p>哈哈，這只是一個測試用的小程式</p>
        </Card>
        <Card>
          <p>一些PWA的功能 - Badging API</p>
          <Button
            outline={true}
            gradientDuoTone="purpleToBlue"
            onClick={() => setAppBadge()}
          >
            設置通知點點(badge)
          </Button>
          <Button
            outline={true}
            gradientDuoTone="purpleToBlue"
            onClick={() => clearAppBadge()}
          >
            清除通知點點
          </Button>
        </Card>
        <Card>
          <p>在手機上分享頁面 - Web Share API</p>
          <Button
            outline={true}
            gradientDuoTone="purpleToBlue"
            onClick={() => shareApp()}
            disabled={!window.navigator.canShare}
          >
            分享這個網站
          </Button>
        </Card>
        <Card>
          <p>通知 - Notification API</p>
          <Button
            outline={true}
            gradientDuoTone="purpleToBlue"
            onClick={() => requestNotification()}
          >
            取得權限 & 發送
          </Button>
        </Card>
      </div>
    </div>
  )
}

export default Test
